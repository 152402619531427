<template>
  <!--   <b-card>-->
   <div v-if="!this.$can('Read', 'Dashboard')" style="align-items: center;padding: 16px; text-align: center;">
      <!-- <p class="">Oops! 😖 You cannot access the requested URL.</p> -->
      <b-img
        style="scale: 0.8;"
          fluid
          :src="notAuthorized"
          alt="Error page"
        />
        <h1 class="mt-2">{{ $t('You dont have permission view this page') }}</h1>
  </div>
  <div v-else>
    <b-row style="box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px; background-color: white; border-radius: 12px; margin-left: 14px;margin-right: 14px; padding-left: 12px; padding-right: 12px;padding-top: 16px;padding-bottom: 20px;">
      <b-col cols="3">
        <p style="margin-bottom: 2px; margin-left: -4px;">{{ $t('Select Dashboard') }}</p>
        <v-select
          v-model="dashboardUuid"
          :options="options"
          label="label"
          style="margin-left: -6px;"
          :reduce="e=>e.value"
          :loading="optionsLoading"
        />
      </b-col>
      <b-col cols="3">
         <p style="margin-bottom: 2px;">{{ $t('Set Date Filter') }}</p>
         <div class="d-flex" style="margin-right: 12px;">
            <b-dropdown
               size="md"
               variant="outline-primary"
               :disabled="dashboardUuid === null || dashboardUuid === ''"
               style="width: 220px; height: 32px; margin-right:6px; border-color: red;"
            >
               <template #button-content>
                  <span class="text-nowrap">{{
                  periodType == 'custom' ?
                     "Custom Date"
                     : yearFilter != null ? yearFilter : $t('Period')
                  }}
                  </span>
               </template>
               <b-dropdown-text style="width: 200px">{{ $t('Select Year or Date Range') }}</b-dropdown-text>
               <b-dropdown-item :key="custom" @click="setCustomDateFilter();">
                  {{ $t('Custom Date') }}
               </b-dropdown-item>
               <b-dropdown-item
                  v-for="year in years"
                  :key="year.name"
                  @click="setCustomYearFilter(year.name);"
               >
                  {{ year.name }}
               </b-dropdown-item>
            </b-dropdown>
            <div v-if="isCustomDateFilter()" style="height: 32px;">
               <date-range-filter
                  ref="dateRangeFilter"
                  style="width: 200px; margin: 0px; margin-top: -10px;"
                  @applyDateFilter="applyDateFilter"
               />
            </div>
         </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <company-contact-dashboard
          :dashboard-uuid="dashboardUuid"
          :date-range="dateRange"
        />
      </b-col>
    </b-row>
  </div>
<!--   </b-card>-->
</template>

<script>
import vSelect from 'vue-select'
import { dashboardService } from '@/services/dashboard'
import CompanyContactDashboard from './CompanyContactDashboard.vue'
import DateRangeFilter from '@/components/Lists/DateRangeFilter.vue'

export default {
   components: {
      vSelect,
      CompanyContactDashboard,
      DateRangeFilter,
   },

   data() {
      return {
         options: [],
         dashboardUuid: '',
         optionsLoading: false,
         yearFilter: null,
         periodType: null,
         years: Array.from({ length: new Date().getFullYear() - 2022 }, (_, i) => new Date().getFullYear() - i).map(year => ({
         name: year,
         })),
         dateRange: [],
         notAuthorized: require('@/assets/images/pages/not-authorized.svg'),
      }
   },
  methods: {
   async applyDateFilter(val) {
      this.dateRange = val
      await this.fetchData()
    },
    isCustomDateFilter() {
      return this.periodType === 'custom'
    },
    async setCustomYearFilter(year) {
        this.yearFilter = year
        this.periodType = 'year'
        this.dateRange = [`01-01-${year}`, `12-31-${year}`]
        await this.fetchData()
    },
    setCustomDateFilter() {
      if (this.periodType !== 'custom') {
        this.$router.push({ query: { page: 1 } })
        this.periodType = 'custom'
        this.yearFilter = null
      }
    },
   },
   watch: {
      dashboardUuid: {
         handler(value) {
               if (this.dashboardUuid !== '' && this.dashboardUuid != null && this.yearFilter == null) {
                  this.setCustomYearFilter(new Date().getFullYear())
               }
         },
      },
      '$route.params': {
         async handler(value) {
            if (value?.tab === 'dashboard') {
               this.optionsLoading = true
               let result
               if (this.$route.name === 'company-view') {
                  result = await dashboardService('dashboard').getMyCategorizedDashboardCompany()
               } else {
                  result = await dashboardService('dashboard').getMyCategorizedDashboardContact()
               }
               const { data } = result
               if (data && Array.isArray(data)) {
                  this.options = data.map(e => ({ value: e.uuidKey, label: e.name }))
               }
               if (this.options.length > 0) {
                  this.dashboardUuid = this.options[0].value
               }
               this.optionsLoading = false
            }
         },
         immediate: true,
      },
   },
}

</script>

<style>
</style>
